import React from 'react'
import Layout from '../components/Layout'
import {graphql, useStaticQuery} from 'gatsby'
import styled from 'styled-components'
import linkedin from '../images/linkedin.svg'
import SEO from '../components/seo'
import Link from "../components/Link";

const Team = () => {
    const data = useStaticQuery(graphql`
    query MyQuery5 {
        allStrapiTeams(sort: {fields: order}) {
          edges {
            node {
              Name
              position
              image{
                  publicURL
              }
              order
              linkedin
            }
          }
        }
      }
`)
    return (
        <Layout>
            <StyledWrapper>
                <SEO title="Team" description="We are a small, cohesive team of designers, entrepreneurs, strategists and engineers."
                path="/team"/>
                <div className="team">
                    <div className="team__main">
                        <div className="header">
                            <p className="page__title">The Team</p>
                            <h3 className="team__content info__desc">We are a small, cohesive team of designers, entrepreneurs,
                                strategists and engineers.</h3>
                        </div>
                        <div className="members pt-5">
                            <div className="row">
                                {data.allStrapiTeams.edges.map(doc => (
                                    <div className="col-sm-3 col-12 pb-4 team__member">
                                        <img className="member__image" src={doc.node.image.publicURL} alt=""/>
                                        <div className="member__details d-flex flex-column">
                                            <div className="member__name d-inline-flex justify-content-between">
                                                <h4>{doc.node.Name}</h4>
                                                {doc.node.linkedin != null ?
                                                    <Link to={doc.node.linkedin} className="pl-4 member__linkedin">
                                                        <img src={linkedin}
                                                             height='18px'
                                                             alt={doc.node.Name}/>
                                                    </Link> : <></>}
                                            </div>
                                            <h5 className="member__title">{doc.node.position}</h5>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </StyledWrapper>
        </Layout>
    )
}

export default Team;

const StyledWrapper = styled.div`
  .team {
    border-left: none;
    transition: 750ms;
  }

  .team__main {
    padding: 150px 100px 100px 100px;
  }

  .member__image {
    width: 100%;
  }

  .member__details {
    display: flex;
    padding: 10px 0px;
  }

  .team__content {
    width: 70%;
  }

  .member__name > h4 {
    margin: 0px;
  }
  
  .member__title {
    font-size: 14px;
  }

  .team__member > h5 {
    font-weight: 300;
  }

  a::before {
    display: none !important;
  }

  @media (max-width: 992px) {
    .team {
      border-left: none;
    }

    .team__main {
      padding: 120px 15px;
    }

    .team__content {
      width: 50%;
    }

    .member__linkedin {
      display: flex;
      align-items: center;
    }

    .team__member {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 45px 15px;
    }
  }
`